import _ from 'lodash';

// 这里写改变state 的方法 this.$store.commit("方法名",payload)
export default {
    //资讯页面/consultingService tab选中项
    setServiceActive(state, active) {
        state.serviceActive = active
        localStorage.setItem('serviceActive', active)
    },

    // 菜单列表-方式、策略
    setMenuList(state, menuList) {
        state.menuList = menuList
    },
    // 获取交易所列表
    setExchangeList(state, exchangeList) {
        state.exchangeList = exchangeList
    },

    // 选中的机器人数据详情
    setRobotData(state, robotData) {
        state.robotData = robotData
    },
    // 量化列表
    setRobotList(state, robotList) {
        // 更新量化接口请求时间
        state.quantificationTime = new Date().getTime();

        // 量化列表
        state.robotList = robotList

        const arr = _.groupBy(robotList, 'tag');
        // 量化各分区列表
        state.zone_1_list = arr.MainZone || [];
        state.zone_2_list = arr.SubMainZone || [];
        state.zone_3_list = arr.ETF || [];
        state.zone_4_list = arr.DeFI || [];
        state.zone_5_list = arr.ETP || [];
        // 量化机器人token列表
        state.botTokenList = []
        robotList.forEach(item => {
            if (item.bot_id > 0) {
                state.botTokenList[item.bot_id] = item.token;
            }
        });
    },

    // 首页机器人数据
    setExchangeRobotList(state, exchangeRobotList) {
        // 更新首页接口请求时间
        state.indexTime = new Date().getTime();
        // 首页列表
        state.exchangeRobotList = exchangeRobotList
    },
};