// 这里写 store 的计算属性
export default {
    //资讯页面/consultingService tab选中项
    serviceActive: state => state.serviceActive,

    // 菜单列表-方式、策略
    menuList: state => state.menuList,
    // 获取交易所列表
    exchangeList: state => state.exchangeList,

    // 选中的机器人数据详情
    robotData: state => state.robotData,
    
    // 量化列表
    robotList: state => state.robotList,
    // 量化各分区列表
    zone_1_list: state => state.zone_1_list,
    zone_2_list: state => state.zone_2_list,
    zone_3_list: state => state.zone_3_list,
    zone_4_list: state => state.zone_4_list,
    zone_5_list: state => state.zone_5_list,
    // 量化机器人token列表
    botTokenList: state => state.botTokenList,
    // 量化接口请求时间
    quantificationTime: state => state.quantificationTime,

    // 首页机器人数据
    exchangeRobotList: state => state.exchangeRobotList,
    // 首页接口请求时间
    indexTime: state => state.indexTime,
};