// store里的 基础state数据
const state = {
    //资讯页面/consultingService tab选中项
    serviceActive: localStorage.getItem('serviceActive') || '1',
    
    // 菜单列表-方式、策略
    menuList: [],
    // 获取交易所列表
    exchangeList: [],

    // 选中的机器人数据详情
    robotData: null,

    // 量化列表
    robotList: [],
    // 量化各分区列表
    zone_1_list: [],
    zone_2_list: [],
    zone_3_list: [],
    zone_4_list: [],
    zone_5_list: [],
    // 量化机器人token列表 [bot_id:token,...] 用于批量设置
    botTokenList: [],
    // 量化接口请求时间
    quantificationTime: 0,

    // 首页机器人数据 {okex:[robot1{},robot2{}]}
    exchangeRobotList:{},
    // 首页接口请求时间
    indexTime: 0,
};

// 配置信息
import Vue from "vue";
import Vuex from "vuex";

// 另外仨抽离出去了
import mutations from "./mutations.js";
import getters from "./getters.js";
import actions from "./actions.js";


Vue.use(Vuex);

export default new Vuex.Store({
    state,
    mutations,
    actions,
    getters,

    // 模块不咋用
    modules: {}
});