fnResize();
window.onresize = function () {
    fnResize();
};
function fnResize() {
    var deviceWidth = document.documentElement.clientWidth || window.innerWidth;
    if (deviceWidth >= 750) {
        deviceWidth = 750;
    }
    if (deviceWidth <= 320) {
        deviceWidth = 320;
    }
    document.documentElement.style.fontSize = deviceWidth / 7.5 + "px";
}

var utils = {
    method1: function () { }
};

export default utils;
