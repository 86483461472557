import qs from "qs";
import axios from "axios";
import { logout } from "@/router";
import Vue from "vue";
// import {Toast} from "vant";
function open() {
    // var loadBox = document.getElementById("loadModel");
    // loadBox ? (loadBox.style.display = "block") : null;
    // Toast.loading({
    //     duration: 0, // 持续展示 toast
    //     forbidClick: true,
    //     message: this.$t('system.Loading'),
    // });
}

function close() {
    // var loadBox = document.getElementById("loadModel");
    // loadBox ? (loadBox.style.display = "none") : null;
    // Toast.clear();
}

// function open() {
//     var loadBox = document.getElementById("loadModel");
//     loadBox.style.display = "block";
// }

// function close() {
//     var loadBox = document.getElementById("loadModel");
//     loadBox.style.display = "none";
// }

axios.interceptors.request.use(
    function (config) {
        //在发送请求之前做一些事情
        return config;
    },
    function (error) {
        // 请求错误做一些事情
        return Promise.reject(error);
    }
);

axios.interceptors.response.use(
    function (response) {
        //对响应数据做一些处理
        return response;
    },
    function (error) {
        // 对响应失败做一些处理
        return Promise.reject(error);
    }
);

//post封装
export function postAjaxv(url, data = {}) {
    open();
    return new Promise((resolve, reject) => {
        axios
            .post(url, data, {
                headers: {
                    "X-Requested-With": "XMLHttpRequest",
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Credentials": true,
                    "crossDomain": true,
                    "x-auth": localStorage.accessToken,
                    'x-app': "tba",
                    'locale': localStorage.getItem('lang')||'kor'
                }
            })
            .then(
                response => {
                    close();
                    resolve(response.data);
                },
                err => {
                    close();
                    reject(err);
                }
            );
    });
}

//post封装
export function postAjax(url, data = {}) {
    open();
    return new Promise((resolve, reject) => {
        axios
            .post(url, qs.stringify(data), {
                headers: {
                    "X-Requested-With": "XMLHttpRequest",
                    "Content-Type": "application/x-www-form-urlencoded",
                    // "Access-Control-Allow-Credentials": true,
                    // "crossDomain": true,
                    "x-auth": localStorage.accessToken,
                    'x-app': "tba",
                    'locale': localStorage.getItem('lang')||'kor'
                }
            })
            .then(
                response => {
                    close();
                    resolve(response.data);
                },
                err => {
                    close();
                    reject(err);
                }
            );
    });
}

export function postAjax2(url, data = {}) {
    open();
    return new Promise((resolve, reject) => {
        if (!/^http/.test(url)) {
            url = global.domain.domain + url;
            // console.log(url);
            // console.log(global.domain.domain);
        }
        axios
            .post(url, qs.stringify(data), {
                headers: {
                    "X-Requested-With": "XMLHttpRequest",
                    "Content-Type": "application/x-www-form-urlencoded",
                    "x-auth": localStorage.accessToken,
                    'x-app': "tba",
                    'locale': localStorage.getItem('lang')||'kor'
                }
            })
            .then(
                response => {
                    close();
                    let d = response.data;
                    if (d.code == 0) {
                        resolve(response.data.result);
                    } else {
						console.log("data: ",data);
						if(data.noToast !== true){
							Vue.prototype.$toast.fail(response.data.msg);
						}
                        if (d.code == 1000) {
                            logout();
                            return;
                        }
                        reject(response.data.msg);
                    }
                },
                err => {
                    close();
					if(data.noToast !== true){
						Vue.prototype.$toast.fail(err.message);
					}
                    reject(err);
                    console.log(err);
                    if (err.response) {
                        if (err.response.status == 1000) {
                            logout();
                            return;
                        }
                    }

                }
            );
    });
}

export function postAjax3(url, data = {}) {
    open();
    return new Promise((resolve, reject) => {
        if (!/^http/.test(url)) {
            url = global.domain.domain + url;
        }
        axios
            .post(url, qs.stringify(data), {
                headers: {
                    "X-Requested-With": "XMLHttpRequest",
                    "Content-Type": "application/x-www-form-urlencoded",
                    "x-auth": localStorage.accessToken,
                    'x-app': "tba",
                    'locale': localStorage.getItem('lang')||'kor'
                }
            })
            .then(
                response => {
                    close();
                    let d = response.data;
                    if (d.code == 0) {
                        resolve(response.data);
                    } else {
                        Vue.prototype.$toast.fail(response.data.msg);
                        if (d.code == 403) {
                            logout();
                            return;
                        }
                        reject(response.data.msg);
                    }
                },
                err => {
                    close();
                    Vue.prototype.$toast.fail(err.message);
                    reject(err);
                    if (err.response.status == 403) {
                        logout();
                        return;
                    }
                }
            );
    });
}



//get封装
export function getAjax(url, data = {}) {
    open();
    return new Promise((resolve, reject) => {
        // if (!/^http/.test(url)) {
        //     url = global.domain2 + url;
        // }
        axios
            .get(url, data, {
                headers: {
                    "X-Requested-With": "XMLHttpRequest",
                    // "Content-Type": "application/json",
                    "x-auth": localStorage.accessToken,
                    'x-app': "tba",
                    'locale': localStorage.getItem('lang')||'kor'
                }
            })
            .then(
                response => {
                    close();
                    resolve(response.data);
                },
                err => {
                    close();
                    reject(err);
                }
            );
    });
}

//保存登录信息
export function saveLoginSession(res) {



    global.accessToken = res.accessToken;
    global.userInfo = res;
    global.defaultToken = res.defaultToken;

    localStorage.accessToken = res.accessToken;
    localStorage.app = res.app;
    localStorage.phone = res.phone;
    localStorage.openid = res.openid;
    if(res.default_exchange=='')
    {
        // localStorage.default_exchange = 'huobi';
        localStorage.default_exchange = 'okex';
    }else{
        localStorage.default_exchange = res.default_exchange;
    }
    localStorage.userInfo = JSON.stringify(res);
}



export function judgeClient() {
    let u = navigator.userAgent;
	console.log(u);
    let isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1;   //判断是否是 android终端
    let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);     //判断是否是 iOS终端
    console.log('是否是Android：' + isAndroid); //true,false
    console.log('是否是iOS：' + isIOS);
    if (isAndroid) {
		console.log('Android')
        return 'Android';
    } else if (isIOS) {
		console.log('IOS')
        return 'IOS';
    } else {
		console.log('PC')
        return 'PC';
    }
}



export function scan() {
    let os = judgeClient();
    if (os == "Android") {
        let result = window.TPUBox.invoke("scanQr", "");
        let vo = JSON.parse(result);
        if (vo.code == 0) {
            return vo.result;
        }
        return "";
    } else if (os == "IOS") {
        // let vo = window.webkit.messageHandlers.invoke.postMessage("scanQr");

        let vo = window.prompt("invoke", "scanQr");
        return vo;


    } else if (os == "PC") {

    }


}

export function newBrowser(url) {
    let os = judgeClient();
    if (os == "Android") {
        // result = window.TPUBox.invoke("toNewBrowser", url);
        // console.log(result)
        window.open(url, '_self');

    } else if (os == "IOS") {
        // window.webkit.messageHandlers.invoke.postMessage("toNewBrowser|" + url);
        window.open(url, '_self');

    } else if (os == "PC") {
        window.open(url, '_blank');
    }
}
