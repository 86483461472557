// 这里写 复杂的处理 或者异步处理 然后触发mutations 再改变state
// this.$store.dispatch("方法名",payload)

import { postAjax2 } from "@/utils/http.js";
import { Toast } from "vant";

export default {
    // 注册、登录、首次加载时触发 获取全局数据
    appInit({ dispatch }) {
        // 菜单列表-方式、策略
        dispatch('setMenuList')
        // 获取交易所列表
        dispatch('getExchangeList')
    },
    // 菜单列表-方式、策略
    setMenuList({ commit }) {
        return new Promise((resolve, reject) => {
            postAjax2("Robot/api/index/getQuantizationMenu", {})
                .then((res) => {
                    commit('setMenuList', res)
                    resolve(res)
                })
                .catch((err) => {
                    Toast.fail({
                        message: err,
                        duration: 3000
                    });
                    reject(err)
                });
        })
    },
    // 获取交易所列表
    getExchangeList({ commit }) {
        return new Promise((resolve, reject) => {
            postAjax2("Trade/Api/Index/getApiSet")
                .then((res) => {
                    commit('setExchangeList', res.list)
                    resolve(res)
                })
                .catch((err) => {
                    reject(err)
                });
        })
    },

    // 量化列表 每次操作后调用this.$store.dispatch('setRobotList')
    setRobotList({ commit,dispatch }, data) {
        return new Promise((resolve, reject) => {
            var reqData
            if (data) {
                reqData = data
            } else {
                var fangshi = 1,
                    type = 'wave',
                    state = 'all';

                // 获取默认交易所信息
                let userinfo = JSON.parse(localStorage.getItem('userInfo'));
                var exchange = userinfo.default_exchange;

                // 获取缓存的头部选项信息-量化
                let info = JSON.parse(localStorage.getItem('head_index_info'));
                if (info != null) {
                    exchange = info.exchange;
                    fangshi = info.fangshi;
                    type = info.type;
                    state = info.state;
                }

                reqData = {
                    exchange,
                    trusteeship_type: fangshi,
                    type,
                    state,
                    // value
                }
            }

            postAjax2('Robot/api/index/searchCurrencyV2', reqData)
                .then((res) => {
                    commit('setRobotList', res.list)
                    resolve(res)
                })
                .catch((err) => {
                    Toast.fail({
                        message: err,
                        duration: 3000
                    });
                    reject(err)
                });
        })
    },

    // 首页机器人数据 this.$store.dispatch('setExchangeRobotList')
    setExchangeRobotList({ commit }, data) {
        return new Promise((resolve, reject) => {
            var reqData
            if (data) {
                reqData = data
            } else {
                var state = 1 //1启动中 0停止
                reqData = { state }
            }

            postAjax2('Robot/Api/Index/getRobotsListWithPrice', reqData)
                .then((res) => {
                    commit('setExchangeRobotList', res)
                    resolve(res)
                })
                .catch((err) => {
                    reject(err)
                });
        })
    },
};